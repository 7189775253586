const jp = {
  almostThere: 'あと少し、回答を続いてください',
  continue: '継続',
  visitUs: '訪問へ',
  newSubmission: '新しい申請表を提出',
  termUse: '利用条件',
  perviousPage: '前のページ',
  chooseInterestCource: 'お興味持つコースを選択してください',
  ignoreCourse: '誓約',
  hearAboutUs: 'iKEYのことをどのように知りましたか。（任意）',
  hearAboutPlaceholder: '例：知人の紹介・FACEBOOK ',
  agreeTo: '{0}の',
  and: 'と',
  privacyStatement: 'プライバシーポリシー',
  ofIkey: '同意します',
  acceptTnC: '利用規約とプライバシーポリシーに同意してください。',
  agree: '{0}の',
  termCondition: '利用規約に同意します',
  of: '',
  submit: '提出',
  page: '{0} ページ',

  learnerInfo: '生徒情報',
  profile: 'プロフィール写真',
  name: '氏名（ローマ字、パスポートと同じ)',
  enterName: 'フルーネムを入力してください。',
  gender: '性別',
  chooseGender: '性別を選択  ',
  male: '男性',
  female: '女性',
  notSpecify: '特になし',
  dob: '生年月日',
  selectDob: '生年月日を選択してください',
  ic: 'パスポート番号',
  enterIc: 'パスポート番号を入力してください',
  contactIc: 'パスポート・身分証明書番号',
  enterContactIc: 'パスポート・身分証明書番号を入力してください。',
  birthCert: '出生証明書番号',
  enterbirthCert: 'マイナンバーカード番号がご存知の場合は、入力してください。',
  schoolName: '学校',
  enterSchoolName: '現在在籍の学校を入力してください。',
  standard: '年生',
  selectStandard: '年生を選択してください。',
  unit: '住所ユニット番号',
  enterUnit: '住所ユニット番号を入力してください。　例：Ｎｏ　１２ーＺ',
  streetName: 'ストリート名',
  enterStreet: 'ストリート名を入力してください。',
  city: '区市町村・地区',
  enterCity: '区市町村・地区を入力してください。',
  postalCode: '郵便番号',
  enterPostal: '郵便番号を入力してください。　例：４５４００',
  country: '国',
  selectCountry: '国を選択してください。',
  state: '都道府県・州',
  selectState: '都道府県・州を入力してください。',
  medicalCondition: '病歴・アレルギー',
  childSpecialInfo:
    '子ども病歴やアレルギー情報をお知らせいだだきましら幸いです。',
  medicalPlaceholder: '例：ぜんそく・ペットの毛にアレルギー',
  next: '次ページ',
  prev: '前ページ',
  sameAbove: '同上',
  contactPerson1: '連絡先１（必須）',
  email: 'メールアドレス',
  enterEmail: 'メールアドレスを入力してください。',
  relationship: '関係',
  selectRelationship: '上記の学生との関係を入力してください。',
  contactNumber: '連絡先',
  enterContact: '電話番号（E.g.0123456789）を入力してください。  ',
  dialingCode: '国際電話コードを',
  selectDialingCode: '国際電話コードを選択',
  race: '民族',
  enterRace: '民族を入力してください。例：大和民族',
  spoken: '母国語',
  enterSpoken: '母国語を入力してください。例：日本語',
  siblingNo: '兄弟姉妹人数 ',
  enterSibling: '兄弟姉妹人数を入力してください。例：３ ',
  position: '家族順',
  enterPosition: '長男や次男かなどを入力してください',
  placeBirth: '出生地',
  enterPlaceBirth: '出生地を入力してください。例：日本',
  nation: '国籍',
  enterNation: '国籍を入力してください。例：日本',
  companyName: '会社名',
  enterCompany: '会社名を入力してください。',
  religion: '宗教',
  enterReligion: '宗教を入力してください。例：仏教 ',
  marital: '婚姻状況',
  enterMarital: '婚姻状況を入力してください。',
  job: '職業',
  enterJob: '職業を入力してください。',
  vehicle: '運転免許証番号',
  enterVehicle: '運転免許証番号を入力してください。',
  primaryContact: '主な連絡先',
  contactPerson2: '連絡先 {0}',
  moreContact: '連絡先を追加',
  enterAnswer: '回答を入力してください。',
  loading: 'ローディング',
  enrollmentForm: '入会フォーム',
  submittedForm: '入会フォームが提出しました。',
  anotherPage: '最後のステップ...',

  //Relationship
  auntie: '叔母',
  caregiver: '介護者',
  driver: '運転者',
  emergencyContact: '緊急連絡',
  father: '父親',
  grandfather: '祖父',
  grandmother: '祖母',
  mother: '母親',
  nanny: '乳母',
  notSpecify: '指定しない',
  spouse: '配偶者',
  studentself: '生徒自身',
  uncle: '叔父',

  //Error Message
  nameRequired: '学生の氏名必須',
  genderRequired: '性別必須',
  dobRequired: '生年月日必須',
  icRequired: 'ＩＣ必須',
  icNotCorrect: '正しいＩＣではありません',
  unitRequired: 'ユニット必須.',
  streetRequired: ' 住所街町必須　',
  postalCodeRequired: '郵便番号必須',
  countryRequired: '国必須',
  stateRequired: '都道府県・州必須',
  emailIncorrect: '正しいメールアドレスではありません',
  emailRequired: 'メールアドレス必須',
  relationshipRequired: '婚姻状況必須',
  dialingCodeRequired: '国際電話コード必須',
  contactNumberRequired: '連絡先電話番号必須',
  agreeTncRequired:
    'アイキー教育の利用規約とプライバシーポリシーに同意することが必要です。',
  agreeCenterRequired: '利用規約と条例に同意することが必要です。',

  // Directory

  // Center
  learnerAge: '学習者年齢',
  relatedCourse: 'コースに関する',
  webiste: 'ウェブサイト',
  openHours: '営業時間',
  fullfillRequirement: '全部の必須欄に入力した？',
  address: 'アドレス',
  nextSection: '次のセクション (強制的)',
};

export default jp;
